.download_list {
  padding: 60px 0;

  .dl_wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .dl_card {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin: 15px 0;

      a {
        margin: 0 15px;
        background-color: #fff;
        height: 100%;
        display: block;
        transition: 0.2s;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;

        img {
          width: 100%;
          height: 425px;
          object-fit: contain;
          margin: auto;
          background: #cff3fa;
        }

        &:hover {
          box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

          div {
            h4 {
              color: #10b5c9;
            }

            svg {
              color: #10b5c9;
            }
          }
        }

        div {
          padding: 40px;
          display: flex;
          justify-content: space-between;

          h4 {
            font-size: 20px;
            color: #003755;
            font-family: "Proxima Nova Lt";
            margin-bottom: 5px;
            text-transform: uppercase;
            width: 100%;
          }

          svg {
            width: 25px;
            height: 25px;
            color: #003755;
          }
        }
      }
    }
  }
}

.ant-modal-title {
  font-size: 20px;
  color: #10b5c9;
  font-family: "Proxima Nova Lt";
  margin-bottom: 5px;
  text-transform: uppercase;
  width: 90%;
  margin: auto;
  text-align: center;
}

.form_card {
  p {
    font-size: 14px;
    margin-bottom: 20px;
    font-style: italic;
  }

  .ant-form-item-label {
    display: block;
    width: 100%;
    text-align: left;
  }

  .ant-input-number {
    width: 100%;
    padding: 10px;
  }

  .ant-input-number-input {
    height: auto;
    padding: 0;
  }

  .ant-input,
  .ant-select-selector {
    padding: 10px;
    font-size: 14px;
  }

  .ant-select-selector {
    padding: 8px 10px;
  }
}

.download_modal {
  .ant-modal-footer {
    display: none;
  }

  .submit_btn {
    button {
      background-color: #10b5c9;
      border: 1px solid #10b5c9;
      padding: 10px 15px;
      height: auto;
    }
  }
}

@media screen and (max-width: 480px) {
  .download_list .dl_wrap .dl_card {
    max-width: 100%;
    flex: 100%;
  }
}
