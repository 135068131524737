$turquoise: #10b5c9;

.tl_year {
  .st0 {
    fill: #ffffff;
  }
  .st1 {
    fill: #01283c;
  }
  .st2 {
    fill: #fff;
  }
  .st3 {
    fill: none;
    stroke: #ffffff;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #ffffff;
    stroke-miterlimit: 10;
    stroke-dasharray: 4.8938, 4.8938;
  }
}

.banner_half {


  &::after {
    background: linear-gradient(0deg, #01283c 0%, rgba(255, 255, 255, 0) 50%);
  }

  .text {
    top: auto;
    bottom: 20%;
    max-width: 450px;

    h3 {
      color: #fff;
      font-family: "Proxima Nova Rg";
      text-transform: uppercase;
      font-size: 56px;

      strong {
        font-family: "Proxima Nova Lt";
      }
    }

    h6 {
      color: #fff;
      font-size: 22px;
      text-transform: uppercase;
      font-family: "Proxima Nova Th";
      padding-bottom: 30px;
      position: relative;

      &::after {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 90px;
        height: 1px;
        background: #fff;
        content: "";
      }
    }
  }
}

.plastic_intro {
  .p_card {
    background: #01283c;

    &:nth-child(even) {
      background: #fff;

      .card_wrap {
        color: #003755;

        .head {
          h6 {
            color: #003755;
          }

          h3 {
            color: #003755;
          }
        }

        .content {
          h4 {
            color: #003755;
          }

          p {
            strong {
              font-family: "Proxima Nova Lt";
              font-size: 30px;
            }
          }
        }
      }
    }

    .card_wrap {
      padding: 100px 0;
      display: flex;
      flex-wrap: wrap;
      color: #f3f3f3;
      overflow: hidden;

      .head {
        width: 30%;

        h3 {
          text-transform: uppercase;
          margin-bottom: 20px;
          color: #10b5c9;
        }

        h6 {
          color: #10b5c9;
          font-size: 16px;
          font-family: "Proxima Nova Rg";
          text-transform: uppercase;

          margin-bottom: 15px;
        }
      }

      .content {
        width: 70%;

        h4 {
          margin-top: 70px;
          color: #f3f3f3;
          font-size: 20px;
          font-family: "Proxima Nova Th";
          text-transform: uppercase;

          &:first-child {
            margin-top: 0;
          }
        }

        p {
          margin-bottom: 25px;

          strong {
            font-size: 32px;
            display: block;
          }
        }
      }
    }
  }
}

.pi_module {
  padding: 80px 0;

  &:nth-child(even) {
    background: #01283c;

    .wrap {
      flex-direction: row-reverse;

      .chart {
        position: absolute;
        left: 0;
      }

      .text {
        padding-left: 40px;

        article {
          color: #fff;
        }
      }
    }
  }

  .head {
    h3 {
      color: #10b5c9;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    p {
      margin-bottom: 15px;
    }
  }
}

.pc_wrap {
  .pc_card {
    display: flex;
    align-items: end;
    margin: 50px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .pl_product {
      width: 150px;
      margin-left: 15px;
    }

    .pc_head {
      display: flex;
      align-items: flex-start;

      img {
        width: 60px;
        margin-right: 20px;
      }

      .title {
        h5 {
          color: #003755;
          font-family: "Proxima Nova Th";
          font-size: 20px;
          margin-top: 70px;
          text-transform: uppercase;
          margin-top: 0;
        }

        h4 {
          margin-top: 0px;
          font-family: "Proxima Nova Lt";
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.r_process {
  background-color: #01283c;
  color: #fff;

  .proces_step {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;

    .step {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0;

      .img {
        width: 40%;
        text-align: center;
        opacity: 0.7;
      }

      img {
        width: 90px;
        margin: auto;
      }

      .text {
        width: 60%;

        h4 {
          color: #10b5c9;
          text-transform: uppercase;
          font-size: 24px;
          line-height: 1.3;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.global_plastic {
  .head {
    color: #003755;

    h3 {
      font-family: "Proxima Nova Rg";
      margin-bottom: 20px;
    }
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text,
    .chart {
      width: 48%;
    }

    .chart {
      img {
        width: 48vw;
      }
    }
  }
}

.industrial_plastic {
  background: #01283c;

  .head {
    color: #fff;

    h3 {
      margin-bottom: 20px;
    }
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text,
    .chart {
      width: 48%;
    }

    .chart {
      img {
        width: 46vw;
      }
    }
  }
}

.wwvi {
  padding: 80px 0;

  .head {
    color: #fff;

    h3 {
      text-transform: uppercase;
      color: #10b5c9;
      margin-bottom: 25px;
      max-width: 490px;
    }

    p {
      margin-bottom: 15px;
    }
  }

  &.intro {
    background-color: #01283c;

    h4 {
      font-size: 32px;
    }
  }

  &.full_image {
    padding: 0;

    img {
      width: 100%;
      height: 430px;
      object-fit: cover;
    }
  }

  &.text_cr {
    background-color: #01283c;
    padding-bottom: 350px;

    p {
      margin-bottom: 15px;
      color: #fff;
    }
  }

  .video {
    margin-top: -350px;
    position: relative;
    z-index: 2;

    iframe {
      width: 100%;
      height: 570px;
      box-shadow: 0 6px 20px 0 rgb(0 0 0 / 25%);
    }
  }
}

.dev_timeline {
  .tl_card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .date {
      position: relative;
      width: 35%;
      max-width: 200px;
      height: 200px;

      h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        font-size: 15px;
        font-family: "Proxima Nova Lt";
      }

      .big_circle {
        position: absolute;
        width: 85%;
        height: 85%;
        border-radius: 50%;
        border: 4px solid #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .small_circle {
        width: 60%;
        height: 60%;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }

    .text {
      width: 65%;
      padding-top: 10px;
      padding-left: 20px;

      h6 {
        color: #10b5c9;
        font-size: 24px;
        font-family: "Proxima Nova Rg";
        line-height: 1.2;
      }

      p {
        margin-top: 5px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .banner_half .text h3 {
    font-size: 45px;
  }

  .plastic_intro .p_card .card_wrap {
    .head {
      width: 100%;
    }

    .content {
      width: 100%;
    }
  }

  .dev_timeline .tl_card {
    margin-bottom: 30px;

    .date {
      width: 100%;
      max-width: 100%;
      height: auto;

      .big_circle,
      .small_circle {
        display: none;
        width: 100%;
      }

      h5 {
        color: #fff;
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0);
        text-align: left;
      }
    }

    .text {
      width: 100%;
      padding-left: 0;
    }
  }

  .pc_wrap .pc_card {
    display: block;

    .pc_head {
      display: block;
    }

    .pl_product {
      display: none;
    }
  }

  .r_process .proces_step .step {
    width: 100%;
  }

  .global_plastic,
  .industrial_plastic {
    .wrap {
      flex-wrap: wrap;

      .text,
      .chart {
        width: 100%;
      }
      .chart {
        img {
          width: 100%;
        }
      }
    }
  }
}
