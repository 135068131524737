.problems {
    padding: 80px 0;
    background: #01283c;

    .prob_card {
        display: flex;
        flex-wrap: wrap;

        .pictures {
            width: 50%;
            height: 576px;
           position: relative;

           .pic_pin {
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
           }

            img {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0;
                visibility: hidden;

                &:first-child {
                    opacity: 1;
                    visibility: visible;
                }

                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .wrapper {
            width: 50%;
            padding: 0 50px;
        }

        .accoridan {
            margin: 30px 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #06405d;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
            }
        }

        .acc_title {
            color: #fff;
            opacity: 0.8;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 24px;
            line-height: 1.3;
            margin-bottom: 10px;
            position: relative;
            padding-right: 30px;

            span {
                position: absolute;
                top: 5px;
                right: 0;
                font-size: 18px;   
                transition: 0.3s;         
            }

            &.active {
                color: #10b5c9;

                span {
                    transform: rotate(180deg);
                }
            }
        }

        .acc_content {
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: 0.1s;
            color: #fff;

            p {
                margin-bottom: 15px;
            }

            &.active {
                height: auto;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .problems .prob_card .pictures {
        height: 466px;
    }
}

@media screen and (max-width: 800px) { 
    .problems .prob_card {
        .pictures, .wrapper {
            width: 100%;
        }

        .wrapper {
            padding: 0;
            margin-top: 50px;
        }

    }




}