.marker {
  background-image: url("./assets/pin.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.portal {
  width: 100%;
  height: calc(100vh - 62px);
  overflow: hidden;
  position: relative;

  .legend_detail {
    position: absolute;
    top: 20px;
    left: 0;
    width: 450px;
    z-index: 9;
    background: #fff;
    padding: 30px;
    transform: translateX(-100%);
    transition: 0.5s;

    &.open {
      transform: translateX(0);
    }

    h3 {
      color: #10b5c9;
      margin-bottom: 15px;
      font-family: "Proxima Nova Rg";
      font-size: 35px;
      text-transform: uppercase;
    }

    .reset_data {
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      color: #fff;
      background-color: #10b5c9;
      z-index: 3;
      font-size: 15px;
      padding: 8px 15px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .legend_click {
    position: absolute;
    bottom: 0px;
    z-index: 3;
    background: #fff;
    display: flex;
    width: 100%;

    .card {
      flex: 1 1;
      padding: 20px 15px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      cursor: pointer;
      align-items: center;

      &:hover {
        .border {
          height: 30%;
        }
      }

      &.expand {
        .border {
          height: 120%;
          // top: -20px;
          // bottom: auto;
        }

        .num h3,
        .desc h4,
        .desc h5 {
          color: #fff;
        }
      }

      .border {
        position: absolute;
        width: 100%;
        height: 10px;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: 0.5s;
      }

      .num {
        width: 30%;
        position: relative;
        z-index: 2;

        h3 {
          font-family: "Roboto Mono", monospace;
          font-weight: 800;
          font-size: 32px;
          line-height: 1;
        }
      }

      .desc {
        width: 70%;
        position: relative;
        z-index: 2;

        h4 {
          max-width: 85px;
          line-height: 1.3;
        }
      }
    }
  }
}

.pinImage {
  width: 50px;
}

.map-container.mapboxgl-map {
  width: 100%;
  height: 100vh;
}

.portal_charts {
  padding: 80px 0px;
  background-color: #003755;

  h3 {
    text-align: center;
    text-transform: uppercase;
    color: #10b5c9;
  }

  p {
    color: #fff;
    text-align: center;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 50px 0px;

    .chart_two {
      width: 34%;
      background-color: #ddeff8;
      padding: 10px 0;
      border: 1px solid #dfdfdf;
      text-align: center;

      svg {
        width: 100%;
      }
    }

    .line_chart {
      width: 100%;
      background-color: #003755;
      padding: 10px 0;
      border: 1px solid #003755;
    }

    path.highcharts-label-box.highcharts-tooltip-box {
      fill: #003755;
    }

    path.highcharts-halo.highcharts-color-undefined {
      fill-opacity: 0.8;
      fill: #10b5c9;
    }

    .highcharts-point {
      display: none !important;
    }

    rect.highcharts-background {
      fill: #003755;
    }

    text {
      fill: #fff !important;
    }

    path.highcharts-grid-line {
      opacity: 0.1;
    }

    .title {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;

      .ant-select-selector {
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        font-size: 18px;
        display: flex;
        width: 180px;
        height: 52px;
        border-radius: 30px;
        align-items: center;
        padding: 0 20px;
      }

      .ant-select-arrow {
        color: #fff;
      }
    }
  }
}

section.daily {
  background: #f8fafb;
  padding: 80px 0;

  h3 {
    text-align: center;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #10b5c9;
  }

  .wrap {
    display: flex;
    justify-content: space-evenly;
    flex: 1 1;
    align-items: center;
  }

  .sing {
    text-align: center;
    margin: 20px 0;
    width: 33.33%;

    h4 {
      color: #003755;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 1.1;

      strong {
        display: block;
      }
    }

    h2 {
      font-size: 42px;
      color: #003755;

      strong {
        span {
          font-size: 42px;
        }
      }

      span {
        font-size: 16px;
        text-transform: uppercase;
        font-family: "Proxima Nova Rg";
      }
    }

    .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke: #003755;
    }

    .ant-progress-circle-trail {
      stroke: #c7c9ca;
    }

    .ant-progress-circle .ant-progress-text {
      color: #003755;
      text-transform: uppercase;
      font-size: 18px;
      width: 70%;
    }

    .ant-progress-circle .ant-progress-inner {
      width: 190px !important;
      height: 190px !important;
    }
  }
}

.divider {
  max-width: 900px;
  padding: 80px 0;
  margin: auto;

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .calc {
      border: 1px solid #10b5c9;
      width: 45%;
      padding: 100px;
      text-align: center;

      a {
        display: inline-flex;
        width: 160px;
        height: 55px;
        background: #10b5c9;
        color: #fff;
        font-size: 18px;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        border-radius: 30px;
      }

      &:last-child {
        background-color: #10b5c9;

        h3 {
          color: #fff;
        }

        a {
          color: #10b5c9;
          background: #fff;
        }
      }

      h3 {
        text-align: center;
        text-transform: uppercase;
        color: #10b5c9;
      }
    }
  }
}

.footprint {
  padding: 80px 0;

  .plastic_footprint {
    position: relative;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      top: 0;
      left: 0;
      background: rgba(0, 55, 85, 0.3);
      z-index: 0;
    }

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 1;

      a {
        background: transparent;
        color: #10b5c9;
        background: #fff;
        border: 1px solid #fff;
        font-size: 18px;
        display: flex;
        width: 180px;
        height: 52px;
        border-radius: 30px;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        margin: 15px auto 0;
        text-transform: uppercase;
      }
    }

    h3 {
      text-transform: uppercase;
      color: #fff;
      text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
    }

    img {
      width: 100%;
      height: 440px;
      object-fit: cover;
    }
  }
}

.omni_calc {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  padding: 20px 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  overflow-y: scroll;
  display: none;

  &.open {
    display: flex;
  }

  span.close_calc {
    position: absolute;
    color: #fff;
    right: 30%;
    font-size: 32px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1400px) {
  .legend_detail {
    max-height: 440px;
    overflow: hidden;

    .contain {
      max-height: 400px;
      overflow-y: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #10b5c9;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .portal .legend_click .card {
    padding: 20px 2px;

    .num {
      width: 100%;
    }

    .desc {
      width: 100%;
    }
  }

  .portal .legend_detail {
    width: 100%;
    position: relative;
    top: 80px;
  }
}

@media screen and (max-width: 480px) {
  .portal {
    height: 430px;
    overflow: visible;

    .legend_click {
      display: flex;
      position: relative;
      flex-wrap: wrap;

      .card {
        padding: 20px 10px;
        flex: inherit;
        width: 50%;

        .num {
          width: 30%;
        }

        .desc {
          width: 70%;
        }

        .border {
          height: 5px;
        }
      }
    }
  }

  .portal .legend_detail {
    width: 100%;
    position: absolute;
    bottom: -350px;
    top: auto;
  }

  section.charts {
    padding-top: 350px;
  }

  section.daily .wrap {
    flex: inherit;
    flex-wrap: wrap;

    .sing {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      text-align: left;

      h2 {
        position: relative;
        width: 50%;

        // span {
        //     position: absolute;
        //     bottom: -5px;
        //     left: 5px;

        // }
      }
    }
  }
}
