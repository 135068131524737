$turquoise: #10b5c9;

.about_text {
  padding: 80px 0;
  background: #01283c;
  color: #fff;

  h3 {
    color: #10b5c9;
    text-transform: uppercase;
    width: 490px;
    max-width: 100%;
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 15px;
  }
}

.mvv {
  padding: 80px 0;
  padding-top: 110px;

  .wrap {
    display: flex;
    flex-wrap: wrap;

    .text {
      width: 40%;

      .card {
        margin: 40px 0;
        color: #003755;

        h3 {
          text-transform: uppercase;
          font-size: 34px;
          margin-bottom: 10px;
          font-family: "Proxima Nova Lt";
          color: #003755;
        }
      }
    }

    .img {
      width: 60%;
      height: 100%;
      text-align: right;

      .swiper-slide-shadow {
        display: none !important;
      }

      img {
        width: 70%;
        height: 567px;
        object-fit: cover;
        margin-right: auto;
      }
    }
  }
}

.sdg_goals {
  padding: 80px 0;

  h3 {
    color: #10b5c9;
    text-align: center;
    width: 540px;
    max-width: 100%;
    margin: auto;
  }

  .img {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      width: 180px;
      margin: 10px;
    }
  }
}

.banner_half {
  height: 100vh;

  &::after {
    background: linear-gradient(0deg, #01283c 0%, rgba(255, 255, 255, 0) 50%);
  }

  .text {
    top: auto;
    bottom: 20%;
    max-width: 450px;

    h3 {
      color: #fff;
      font-family: "Proxima Nova Rg";
      text-transform: uppercase;
      font-size: 56px;

      strong {
        font-family: "Proxima Nova Lt";
      }
    }

    h6 {
      color: #fff;
      font-size: 22px;
      text-transform: uppercase;
      font-family: "Proxima Nova Th";
      padding-bottom: 30px;
      position: relative;

      &::after {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 90px;
        height: 1px;
        background: #fff;
        content: "";
      }
    }
  }
}

.abt-banner {
  background-color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .container {
    width: 100%;
  }

  .text {
    width: 610px;
    max-width: 100%;

    h2 {
      color: #10b5c9;
      font-size: 62px;
      line-height: 1.1;
      font-family: "Proxima Nova Th";

      strong {
        font-family: "Proxima Nova Lt";
      }
    }
  }
}

.abt_objective {
  .objectives {
    // background-image: linear-gradient(to right top, #ffffff, #f8fcff, #ebfaff, #dafaff, #cafafe);
    background: #01283c;

    .wrap {
      .card {
        background: #10b5c9 !important;
      }
    }

    .objective_head {
      color: #fff;
    }
  }
}

.programs {
  padding: 80px 0;

  .pg_card {
    padding: 40px 0;
    border-bottom: 1px solid #e5e5e5;

    a {
      display: flex;
      flex-wrap: wrap;

      &:hover {
        .text {
          h3 {
            color: #10b5c9;
          }

          .read_more {
            h6 {
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }

    .img {
      width: 30%;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }

    .text {
      width: 70%;
      padding-left: 40px;
      color: #003755;

      h3 {
        text-transform: uppercase;
        font-size: 34px;
        margin-bottom: 10px;
        font-family: "Proxima Nova Lt";
        color: #003755;
      }

      p {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 90px;
      }

      .read_more {
        h6 {
          margin-top: 20px;
          margin-bottom: 20px;
          color: #01cbe1;
          text-transform: uppercase;
          font-size: 16px;
          transition: 0.5s;
        }
      }
    }
  }
}

.milestone {
  position: relative;
  min-height: calc(100vh - 65px);
  overflow: hidden;
  background: #003755;

  .milestone_wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .milestone_sticky {
    background: #01283c;
    color: #fff;
    width: 450px;
    max-width: 100%;
    padding: 25px 20px 0;
    min-height: 320px;
    height: 100%;

    h3 {
      text-transform: uppercase;
      color: #10b5c9;
      font-size: 35px;
      margin-bottom: 20px;
    }

    article {
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 145px;
      font-size: 18px;
      font-family: "Proxima Nova Rg";
      line-height: 1.55;

      ul {
        padding-left: 0;
      }

      h5 {
        color: #01cbe1;
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    a {
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #01cbe1;
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  img {
    width: 100%;
  }

  .milestone_content {
    position: relative;
    margin: 80px 0;
  }

  .milestone_figure {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    display: block;
    line-height: 0;

    img {
      max-width: none;
      object-fit: cover;
      object-position: center;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: opacity 1s ease;
    }
  }

  .milestone_scroller {
    max-width: 500px;
    height: 100%;
    background: #fff;
    overflow: hidden;

    .milestone_head {
      position: relative;
      background: #01cbe1;
      color: #fff;
      padding: 25px 20px;

      h3 {
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 10px;
        font-size: 48px;
        font-family: "Proxima Nova Lt";
      }
    }

    .milestone_listing {
      padding: 25px 20px;

      .list_single {
        margin: 40px 0;

        h4 {
          color: #10b5c9;
          font-size: 20px;
          font-family: "Proxima Nova Thin";
          line-height: 1.2;
          text-transform: uppercase;
          margin-bottom: 30px;
        }

        &:first-child {
          margin-top: 0;
        }

        ul {
          li {
            position: relative;
            border-left: 1px solid #01283c;
            padding: 20px 20px;

            &.active {
              &::after {
                opacity: 0.15;
              }
            }

            h5 {
              font-size: 20px;
              font-family: "Proxima Nova Lt";
              line-height: 1.2;
              text-transform: uppercase;
            }

            p {
              font-size: 14px;
            }

            &:hover {
              cursor: pointer;

              &::after {
                opacity: 0.15;
              }

              &::before {
                transform: scale(2) translateY(-50%) translateX(-1px);
              }
            }

            &::before {
              width: 10px;
              height: 10px;
              background: #01283c;
              content: "";
              position: absolute;
              top: 50%;
              left: -5px;
              transform: translateY(-50%);
              border-radius: 50%;
              transition: 0.5s;
            }

            &::after {
              width: 120%;
              height: 100%;
              top: 0;
              left: -25px;
              position: absolute;
              content: "";
              background: #01283c;
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.see_other {
  text-align: center;
  margin-bottom: 40px;

  a {
    display: inline-flex;
    background: #003755;
    color: #fff;
    padding: 20px 30px;
    text-transform: uppercase;
  }
}

.ms_content {
  padding: 80px 0;
  width: 550px;
  max-width: 100%;
  margin: auto;

  .description {
    margin: 30px 0;

    h5 {
      margin-bottom: 20px;
      margin-bottom: 10px;
    }
  }

  article {
    font-size: 18px;
    font-family: "Proxima Nova Rg";
    line-height: 1.55;
    margin: 40px 0 0;

    h5 {
      margin: 30px 0 5px 0;
    }

    img {
      max-width: 100%;
    }
  }

  article,
  .description {
    h5 {
      color: #01cbe1;
      text-transform: uppercase;
      font-size: 22px;
    }

    li {
      margin-bottom: 15px;
      position: relative;
      padding-left: 20px;

      &::after {
        position: absolute;
        width: 10px;
        height: 10px;
        content: "";
        top: 5px;
        background-color: #003755;
        left: 0;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .plastic_intro .p_card .card_wrap {
    .head {
      width: 100%;
    }

    .content {
      width: 100%;
    }
  }

  .mvv .wrap {
    .text,
    .img {
      width: 100%;
      overflow: hidden;
    }

    .img {
      text-align: left;
    }
  }

  .mvv .wrap .img img {
    height: 307px;
  }

  .sdg_goals {
    width: 100%;

    overflow: hidden;

    .img img {
      width: 90px;
    }
  }

  .abt_objective {
    width: 100%;
    overflow: hidden;
  }

  .programs .pg_card {
    .img,
    .text {
      width: 100%;
      padding-left: 0;
    }

    .text {
      margin-top: 20px;
    }
  }
}
