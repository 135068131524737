footer {
  position: relative;
  z-index: 2;

  .suscribe {
    color: #fff;
    text-align: center;
    padding-bottom: 80px;

    h3 {
      font-family: "Proxima Nova Th";
      text-transform: uppercase;
      color: #10b5c9;
      margin-bottom: 20px;
    }

    p {
      max-width: 520px;
      margin: auto;
      font-family: "Proxima Nova Th";
      line-height: 1.4;
      margin-bottom: 30px;
    }

    .btn {
      width: 60%;
      margin: auto;
      display: flex;
      justify-content: center;

      button {
        background: #10b5c9;
        border: 0;
        display: inline-flex;
        height: 48px;
        width: 110px;
        font-family: "Proxima Nova Rg";
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .footer_top {
    .wrap {
      border-top: 1px solid #0d4564;
      border-bottom: 1px solid #0d4564;
      padding: 30px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-size: 24px;
        color: #fff;
        font-family: "Proxima Nova Th";
        text-transform: uppercase;
        font-weight: 100;
      }

      .social_icon {
        a {
          color: #fff;
          margin: 0 15px;
          font-size: 18px;
          opacity: 0.8;

          &:last-child {
            margin-right: 0;
          }

          svg {
            path {
              stroke-width: 1px;
            }
          }
        }
      }
    }
  }

  .footer_last {
    padding: 40px 0px;
    background-color: #fff;

    .legal_detail img {
      width: 180px;
      margin-bottom: 10px;
    }

    .wrap {
      display: flex;
      justify-content: space-between;
    }

    h4 {
      color: #003755;
      text-transform: uppercase;
      font-family: "Proxima Nova Lt";
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      color: #003755;
    }

    .footer_contact {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .logo_support {
      display: flex;
      width: 40%;

      img {
        height: 62px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  .footer_bottom {
    padding: 40px 0px;
    background-color: #fff;

    .wrap {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    a {
      color: #fff;
    }
  }

  .footer_copy {
    padding: 20px 0;
    background: #fff;
    border-top: 1px solid #e3e3e3;

    .wrap {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    a {
      color: #003755;
    }

    p {
      color: #003755;
      font-size: 14px;
      font-family: "Proxima Nova Rg";
      font-weight: 100;

      margin-right: 10px;
    }
  }
}

.suscribe {
  padding: 80px 0;
  background: #10b5c9;

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .newsletter {
      width: 50%;
      color: #fff;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 26px;
        background: #10b5c9;
        left: 0;
        bottom: 0;
      }

      h3 {
        text-transform: uppercase;
        color: #fff;
        font-size: 24px;
      }

      iframe {
        margin-top: 32px;
        position: relative;
      }
    }

    .socail_icons {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .card {
        width: 33.33%;
        text-align: center;
        margin: 15px 0;

        a {
          color: #fff;

          i {
            font-size: 22px;
          }

          p {
            border: 1px solid #fff;
            text-transform: uppercase;
            font-size: 14px;
            width: 60%;
            margin: 10px auto;
            padding: 4px 0;
          }
        }
      }
    }
  }
}

.suscribe_become_saathi {
  padding: 80px 0;
  background-color: #b5dcf1;

  .head {
    text-align: center;

    h3 {
      font-family: "Proxima Nova Rg";
      text-transform: uppercase;
      color: #10b5c9;
      margin: auto;
      margin-bottom: 20px;
      width: 410px;
      max-width: 100%;
    }

    p {
      max-width: 100%;
      width: 520px;
      margin: auto;
      line-height: 1.4;
      margin-bottom: 30px;
    }
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 100px;

    .saathi {
      width: 32%;
      background-color: #fff;
      padding: 20px;

      .img {
        text-align: center;
        margin-top: -105px;
        margin-bottom: 50px;

        img {
          width: 145px;
          height: 145px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      h4 {
        text-transform: uppercase;
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 10px;
        position: relative;
        padding-right: 30px;
        color: #10b5c9;
      }

      a {
        margin-top: 20px;
        margin-bottom: 20px;
        color: #01cbe1;
        text-transform: uppercase;
        font-size: 14px;
        transition: 0.5s;
        display: inline-block;
        position: relative;
        opacity: 0.8;

        span {
          padding-left: 10px;
          position: relative;
        }
      }
    }
  }
}

.sitemap {
  padding-bottom: 50px;

  .wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .sm {
    width: 25%;

    &:nth-child(2) {
      padding-left: 40px;
    }

    &:nth-child(3) {
      padding-left: 40px;
    }

    &:last-child {
      padding-left: 50px;
    }

    h4 {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 1.3;
      margin-bottom: 10px;
      position: relative;
      padding-right: 30px;
      color: #10b5c9;
    }

    a {
      display: block;
      margin: 5px 0;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      position: relative;
    }
  }
}

@media screen and (max-width: 800px) {
  footer .footer_last {
    .wrap {
      display: block;
    }

    .logo_support {
      width: 100%;
    }

    .footer_contact {
      width: 100%;
      padding-left: 0;
      display: block;

      .legal_detail {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .suscribe_become_saathi .wrap {
    display: block;

    .saathi {
      width: 100%;
      margin-bottom: 150px;
    }
  }

  .suscribe .wrap {
    .saathi {
      width: 100%;
      margin-bottom: 100px;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  .sitemap .sm {
    width: 100%;
    margin: 20px 0;
    padding-left: 0 !important;
  }

  footer .logo_support {
    display: block;

    .img {
      margin: 15px 0;
      width: 50%;
    }

    img {
      width: 100%;
    }
  }
}
