.story_list {
  padding: 60px 0;

  .wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .story_card {
      flex: 0 50%;
      max-width: 50%;
      margin: 80px 0;

      a {
        margin: 15px;
        background-color: #fff;
        height: 100%;
        display: block;
        transition: 0.2s;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
        text-align: center;

        &:hover {
          box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

          .detail {
            .read-more {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }

      figure {
        margin-bottom: 20px;

        img {
          width: 285px;
          height: 285px;
          border-radius: 50%;
          margin-top: -90px;
          object-fit: cover;
        }
      }

      .detail {
        padding: 0 20px;

        h4 {
          font-size: 20px;
          color: #003755;
          font-family: "Proxima Nova Lt";
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        article {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          font-size: 14px;
          line-height: 1.3;
          color: #003755;
          font-family: "Proxima Nova Rg";
        }

        .read-more {
          text-transform: uppercase;
          color: #10b5c9;
          font-family: "Proxima Nova Lt";
          padding-top: 30px;
          transform: translateY(15px);
          opacity: 0;
          transition: 0.5s;
        }
      }
    }
  }
}

section.story_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;

  .wrap {
    display: flex;
    background: #fff;
    height: 90vh;
    position: relative;
    flex-wrap: wrap;

    span.close {
      position: absolute;
      top: -20px;
      right: -15px;
      background: #fff;
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;
    }

    .img {
      width: 50%;

      img {
        width: 90%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      width: 50%;
      height: 100%;
      overflow-y: scroll;
      padding: 40px 0;

      article {
        padding-right: 40px;
      }

      h3 {
        color: #10b5c9;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 15px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .story_list .wrap .story_card {
    max-width: 100%;
    flex: 100%;
  }

  section.story_popup .wrap {
    .img {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .text {
      width: 100%;
      padding: 20px;
      height: 59%;

      article {
        padding-right: 0;
      }
    }
  }
}
