.media_lists {
  padding: 60px 0;

  .media_head {
    h3 {
      text-transform: uppercase;
      font-size: 24px;
      color: #10b5c9;
    }
  }

  .media_card_wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .media_card {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 15px 0;
    }
  }

  .media_card {
    &.coverage {
      a {
        img {
          object-fit: contain;
        }
      }
    }

    a {
      margin: 15px;
      display: block;
      display: flex;
      flex-wrap: wrap;
      box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
      transition: 0.2s;

      &:hover {
        box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

        .desc {
          h4 {
            color: #10b5c9;
          }
        }
      }

      img {
        width: 30%;
        height: 150px;
        object-fit: cover;
      }

      .desc {
        padding: 15px;
        width: 70%;

        h4 {
          font-size: 20px;
          color: #003755;
          font-family: "Proxima Nova Lt";
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        p {
          font-size: 14px;
          line-height: 1.2;
          color: #248d9a;
          text-transform: uppercase;
          font-family: "Proxima Nova Rg";
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .media_lists .media_card_wrap .media_card {
    max-width: 100%;
    flex: 100%;
  }
}
