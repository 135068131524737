.team {
    // background-image: linear-gradient(to right top, #ffffff, #f8fcff, #ebfaff, #dafaff, #cafafe);
    padding-bottom: 40px;

    .wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        .team_card {
            flex: 0 0 25%;
            max-width: 25%;
            margin: 15px 0;
            

            figure {
                img {
                    width: 100%;
                    height: 253px;
                    object-fit: cover;
                }
            }

            .detail {
                margin-top: 20px;
                padding: 10px;
                

                h4 {
                    font-size: 20px;
                    color: #003755;
                    font-family: "Proxima Nova Lt";
                    margin-bottom: 5px;
                    text-transform: uppercase;

                }

                h5 {
                    font-size: 14px;
                    line-height: 1.2;
                    color: #248d9a;
                    text-transform: uppercase;
                    font-family: "Proxima Nova Rg";
                    max-width: 80%;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                        max-width: 100%;
                    }
                }
            }
        }

        .tc_wrap {
            margin: 15px;
            background-color: #fff;
            height: 100%;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        }
    }
}


@media screen and (max-width: 480px) { 

    .team {
    
        .wrap {
            margin: 0;
    
            .team_card {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 15px 0;
            }
        }
    }

}