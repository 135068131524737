.inner_banner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.light {
    // background-image: linear-gradient(to right bottom, #ffffff, #f8fcff, #ebfaff, #dafaff, #cafafe);
    background: #fff;
  }

  &.dark {
    background-color: #01283c;
  }

  &.smallHeight {
    height: 350px;
  }

  .container {
    width: 100%;
  }

  .text {
    width: 610px;
    max-width: 100%;

    span {
      font-size: 16px;
      color: #14b6ca;
      font-family: "Proxima Nova Rg";
      display: block;
      margin-bottom: 40px;
      text-transform: uppercase;
    }

    h2 {
      color: #10b5c9;
      font-size: 42px;
      line-height: 1.18;
      font-family: "Proxima Nova Rg";
      text-transform: uppercase;
      position: relative;

      &::before {
        position: absolute;
        top: -20px;
        content: "";
        left: 0;
        width: 90px;
        height: 1px;
        background: #10b5c9;
      }
    }
  }

  .figure {
    position: absolute;
    width: 500px;
    height: 500px;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    img {
      position: absolute;
    }
  }
}

.banner_half {
  height: 430px;
  background-position: center !important;
  background-size: cover !important;

  // &::after {
  //     background: linear-gradient(0deg, #01283c 0%, rgba(255, 255, 255, 0) 50%);
  // }

  // &::before {
  //     background: linear-gradient(90deg, #01283c 0%, rgba(255, 255, 255, 0) 50%);

  // }

  .text {
    top: auto;
    bottom: 20%;
    max-width: 470px;

    h3,
    article {
      color: #fff;
      font-family: "Proxima Nova Rg";
      text-transform: uppercase;
      font-size: 56px;
      line-height: 1.1;

      strong {
        font-family: "Proxima Nova Lt";
      }
    }

    h6 {
      color: #fff;
      font-size: 22px;
      text-transform: uppercase;
      font-family: "Proxima Nova Th";
      padding-bottom: 30px;
      position: relative;

      &::after {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 90px;
        height: 1px;
        background: #fff;
        content: "";
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .banner_half .text h3 {
    font-size: 45px;
  }
}
