$turquoise: #10b5c9;

.pl_banner {
  .inner_banner {
    .text {
      width: 460px;
    }

    .pr_banner_svg {
      height: 500px;
      position: absolute;
      right: 5%;
      top: 50%;
      -webkit-transform: translateY(-40%);
      transform: translateY(-40%);
      width: 600px;
    }
  }
}

.pl_desc {
  padding: 80px 0;
  background: #01283c;
  color: #fff;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.steps {
  background: #01283c;
  padding: 80px 0;

  .steps_head {
    color: #fff;
    width: 570px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 70px;
    text-align: center;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
}

.steps_slider {
  padding-top: 80px;

  h4 {
    color: #10b5c9;
    text-transform: uppercase;
    font-size: 34px;
    text-align: center;
  }

  .item {
    color: #fff;
    height: 450px;

    &:first-child,
    &:nth-child(3) {
      .text {
        margin-left: auto;
        top: 15%;
        right: 8%;
      }
    }

    &:nth-child(2),
    &:last-child {
      .text {
        margin-left: auto;
        top: 15%;
        left: 20%;
      }
    }

    &:last-child {
      .text {
        top: 5%;
      }
    }

    figure {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .text {
      max-width: 40%;
      position: absolute;

      ul {
        list-style: circle;
      }

      li {
        font-size: 20px;
        font-family: "Proxima Nova Rg";
        line-height: 1.65;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    top: 0;
  }

  span.swiper-pagination-bullet {
    width: 250px;
    height: auto;
    border-radius: 0;
    color: #fff;
    background-color: transparent;
    border: 1px solid #10b5c9;

    .page_wrap {
      display: flex;
      align-items: center;

      label {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #10b5c9;
      }

      h5 {
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        padding: 5px;
        margin-left: 10px;
      }
    }
  }
}

.rec {
  padding: 80px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ul {
    position: relative;
    width: 560px;
    height: 560px;
    max-width: 40%;
    background-color: #72effa;
    border-radius: 50%;
    // animation: rotate 45s reverse linear infinite;
  }

  .video {
    width: 60%;
    padding-left: 20px;

    h3 {
      font-size: 28px;
      font-weight: bold;
    }

    video {
      width: 100%;
    }
  }

  li {
    position: absolute;
    display: block;
    text-align: center;
    max-width: 100px;
    cursor: pointer;

    figure {
      width: 95px;
      height: 95px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        border: 1px solid #01283c;
        border-radius: 50%;
        margin-bottom: 5px;
      }
    }

    h4 {
      font-weight: bold;
      line-height: 1.2;
    }

    img {
      width: 45px;
    }
  }
}

// $container-size: 540px;
// $d1: 100px;
// $d2: 240px;
// $d3: 320px;
// $d4: 440px;

// .page {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .page .container {
//     position: relative;
//     width: #{$container-size};
//     height: #{$container-size};
//   }

// .middle, .outer {
//   position: absolute;
//   pointer-events: none;
// }

// .middle {
//   left: calc((#{$container-size} - #{$d3}) / 2);
//   top: calc((#{$container-size} - #{$d3}) / 2);
//   width: #{$d3};
//   height: #{$d3};
//   animation: rotate 35s linear infinite;
// }

// .outer {
//   left: calc((#{$container-size} - #{$d4}) / 2);
//   top: calc((#{$container-size} - #{$d4}) / 2);
//   width: #{$d4};
//   height: #{$d4};
//   animation: rotate 45s reverse linear infinite;
// }

// .middle.dots, .outer.dots {
//   border: 0.5px solid #5b5f62;
//   border-radius: 500px;
// }

// .middle.dots { border-color: #eb860e; }

// .dot {
//   position: absolute;
//   width: 48px;
//   height: 48px;
//   left: -24px;
//   top: calc(50% - 24px);
//   border-radius: 100px;
//   background: #fff center center;
//   background-size: 85%;
//   box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
// }

// .icon-buch { background-image: url(https://www.designeinheit.de/kunden/mm/icon_buch.svg); }
// .icon-screen { background-image: url(https://www.designeinheit.de/kunden/mm/icon_screen.svg); }
// .icon-kompass { background-image: url(https://www.designeinheit.de/kunden/mm/icon_kompass.svg); }
// .icon-film { background-image: url(https://www.designeinheit.de/kunden/mm/icon_film.svg); }

// .target {
//   position: absolute;
//   width: 64px;
//   height: 64px;
//   left: -32px;
//   top: calc(50% - 32px);
//   border-radius: 32px;
//   pointer-events: auto;
//   cursor: pointer;
// }

// .dot:nth-child(2) { left: calc(100% - 24px); }
// .target:nth-child(2) { left: calc(100% - 32px); }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1400px) {
  .rec ul {
    height: 450px;
    width: 450px;
  }
}
