.form_wrapper {
  padding: 100px 0;
  background: #fff;

  .head {
    text-align: center;
    width: 570px;
    max-width: 100%;
    color: #fff;
    margin: 0 auto 20px;
    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    p {
      color: #01283c;
    }
  }
}

.form {
  padding-top: 40px;

  .submit_btn {
    margin: 20px;

    button {
      background: #10b5c9;
      border: 0;
      color: #fff;
      text-transform: uppercase;
      padding: 13px 20px;
      height: 48px;
      display: flex;
      align-items: center;
    }
  }

  .ant-input-number {
    background-color: transparent;
    border: 1px solid transparent;
  }

  .form_card {
    margin: 20px;
    color: #fff;

    .ant-form-item-label {
      display: block;
      width: 100%;
      text-align: left;
    }

    .ant-picker-focused {
      padding: 0;
      border-color: transparent;
      box-shadow: none;
    }

    .ant-picker {
      color: #fff;
      padding: 0;
      border: 0;
      width: 100%;
    }

    .ant-picker-input {
      background-color: #10b5c9;
    }

    .ant-picker-suffix {
      margin-right: 10px;
      color: #fff;
    }

    label {
      color: #01283c;
      display: block;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    .ant-input-number {
      width: 100%;
    }

    input#Bailing\ Partner_bailing_request_phone {
      padding: 20px 10px;
      border: 1px solid transparent !important;
    }

    input,
    textarea {
      width: 100%;
      background: #fcfcfc;
      padding: 10px;
      border: 1px solid #ccc;
      color: #01283c;
      height: auto;

      &:focus {
        outline: none;
        border: 1px solid #10b5c9;
      }
    }

    textarea {
      height: 150px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.car_head {
  text-align: center;
  padding: 100px 0;
  width: 580px;
  max-width: 100%;
  margin: auto;
  color: #003755;

  h3 {
    color: #10b5c9;
    margin-bottom: 20px;
  }

  a {
    color: #fff;
    background: #10b5c9;
    font-size: 18px;
    text-transform: uppercase;
    padding: 10px 20px;
    display: inline-block;
    margin-top: 25px;
  }
}

table.vacancy_table {
  width: 100%;

  th,
  td {
    font-size: 16px;
    padding: 15px;
    font-family: "Proxima Nova Lt";
  }

  thead {
    tr {
      background: #fafafa;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #dadada;
      padding: 10px;

      a {
        background: #10b5c9;
        padding: 10px;
        border-radius: 15px;
        color: #fff;
        font-size: 13px;
      }
    }
  }

  td {
    font-size: 14px;
  }
}

.job_table {
  padding: 80px 0;

  .table_post {
    margin-top: 40px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px;
  display: flex;
  align-items: center;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper:hover {
  background: transparent;
}

.ant-input-number-handler-wrap {
  display: none;
}

.apply_btn {
  background: #10b5c9;
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
}

.form_card {
  .ant-col.ant-form-item-control {
    width: 100%;
    min-width: 360px;
  }
}

.carrer_tab {
  .ant-tabs-tab-btn {
    color: #003755;
    text-transform: uppercase;
    font-family: "Proxima Nova Lt";
    font-size: 18px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #003755;
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    background: #003755;
  }

  .ant-tabs-content-holder {
    padding: 40px 0;
    font-size: 18px;
    font-family: "Proxima Nova Rg";
    line-height: 1.55;

  }
}