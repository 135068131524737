header {
  margin: auto;
  z-index: 999;
  background: #fff;
  position: relative;
  z-index: 99;

  .mb_click {
    display: none;

    span {
      display: block;
      width: 30px;
      background-color: #10b5c9;
      height: 1px;
      margin: 10px;
      transition: 0.5s;
    }
  }

  .portal_btn {
    color: #fff !important;
  }

  .logo {
    display: flex;
    align-items: center;

    img {
      height: 50px;
    }
  }

  .nav_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .primary {
    margin-left: 30px;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3vw;
    position: relative;

    .secondary {
      nav {
        ul {
          li {
            &.has_dropdown {
              .sub_menu {
                width: 175px;
              }
            }

            a {
              font-size: 14px;
            }
          }
        }
      }
      .portal_btn {
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Proxima Nova Rg";
        position: relative;
        z-index: 2;
        background-color: #10b5c9;
        display: block;
        text-align: center;
        padding: 5px 10px;
      }
    }

    nav {
      ul {
        display: flex;
        align-items: center;

        li {
          padding: 15px 25px;
          position: relative;

          &.has_dropdown {
            cursor: pointer;

            &:hover {
              .sub_menu {
                opacity: 1;
                visibility: visible;
                padding-top: 8px;
                transform: translateY(0);
              }
            }

            .sub_menu {
              position: absolute;
              top: 53px;
              left: 0;
              z-index: 3;
              width: 260px;
              background-color: transparent;
              opacity: 0;
              visibility: hidden;
              transform: translateY(20px);
              transition: 0.5s;

              .sm_wrap {
                position: relative;

                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #01283c;
                  transform-origin: center center;
                  transform: rotate(-45deg);
                  position: absolute;
                  top: -7px;
                  left: 32px;
                  margin-left: -7px;
                  z-index: -1;
                }
              }

              .menu_tree_div {
                position: relative;
                &:hover {
                  .menu_tree {
                    display: block;
                  }
                }
              }

              .menu_tree {
                position: absolute;
                right: -212px;
                top: 0;
                display: none;
                width: 210px;

                &::before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #01283c;
                  transform-origin: center center;
                  transform: rotate(45deg);
                  position: absolute;
                  top: 16px;
                  left: 0;
                  margin-left: -7px;
                }
              }

              a {
                display: block;
                background-color: #fff;
                border-bottom: 1px solid #dae1e2;
                color: #003755;
                padding: 12px 22px;
                font-family: "Proxima Nova Lt";
                font-size: 14px;
                transition: 0;
                position: relative;

                &:hover {
                  .menu_tree {
                    display: block;
                  }
                }

                &:last-child {
                  border-bottom: 0;
                }
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            color: #003755;
            text-transform: uppercase;
            font-size: 17px;
            font-family: "Proxima Nova Lt";
          }
        }
      }
    }
  }
}

a {
  &.active {
    color: #10b5c9 !important;

    &.portal_btn {
      color: #fff !important;
    }
  }
}

@media screen and (max-width: 1400px) {
  header .wrap nav ul li.has_dropdown:hover .sub_menu {
    padding-top: 0;
  }

  header {
    // max-width: 96%;

    .logo {
      width: 20%;
    }

    .nav_wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .wrap {
      padding: 0 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      nav {
        ul {
          li {
            padding: 12px 18px;
          }
        }
      }
    }
  }

  .banner .text h1 {
    line-height: 1.1;
  }
}



@media screen and (max-width: 1085px) {
  header .wrap .secondary nav ul li.has_dropdown .sub_menu {
    width: 100%;
  }

  span.blinker {
    margin-left: 15px !important;
  }

  header .wrap nav ul li.has_dropdown:hover .sub_menu {
    padding-top: 10px;
  }

  header .wrap nav ul li.has_dropdown .sub_menu .menu_tree {
    position: relative;
    right: auto;
    top: 0;
    display: none;
    width: 100%;
    left: 40px;

    &::before {
      display: none;
    }
  }

  header {
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .nav_wrapper {
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      top: 70px;
      left: 0;
      background: #fff;
      display: block;
      transform: translateX(100%);
      transition: 0.5s;
      opacity: 0;
      visibility: hidden;

      &.open {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }

      .primary {
        margin-left: 0;
      }
    }

    .logo {
      img {
        width: 200px;
      }
    }

    .wrap {
      nav {
        ul {
          display: block;

          li {
            padding: 20px 10px;
            border-bottom: 1px solid #d4e2ea;

            &.has_dropdown {
              a {
                position: relative;
                width: 100px;
                display: inline-block;

                &::after {
                  content: "";
                  position: absolute;
                  right: -40px;
                  top: 41%;
                  z-index: 11;
                  display: block;
                  width: 10px;
                  height: 10px;
                  border-top: 2px solid #10b5c9;
                  border-left: 2px solid #10b5c9;
                  transform: rotate(130deg);
                }
              }

              &:hover {
                .sub_menu {
                  height: auto;
                }
              }

              .sub_menu {
                position: relative;
                top: 0;
                left: 0;
                z-index: 3;
                width: 100%;
                background-color: transparent;
                height: 0;
                // opacity: 1;
                // visibility: visible;
                transform: translateY(0);
                transition: 0.5s;
                overflow: hidden;

                a {
                  width: 100%;

                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      .secondary {
        nav {
          ul {
            li {
              &:last-child,
              &:nth-child(3) {
                border-bottom: 0;
              }

              &.has_dropdown {
                a {
                  &::after {
                    content: "";

                    top: 35%;

                    width: 8px;
                    height: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  header .wrap nav ul li.has_dropdown .sub_menu .sm_wrap::before {
    display: none;
  }

  header .mb_click {
    display: block;

    &.close {
      span {
        &:last-child {
          display: none;
        }

        &:first-child {
          transform: rotate(45deg) translate(8px, 8px);
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

span.blinker {
  width: 8px;
  height: 8px;
  background: #10b5c9;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
