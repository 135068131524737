
.faq{
    background-color: #01283c;
    padding: 80px 0;

    .faq_collapse {
        background-color: #01283c;
        border: 0;
        padding: 0px;

        .ant-collapse-item {
            border-bottom: 1px solid #0d3e57;

            &:last-child {
                border-bottom: 0;
            }
        }

        .ant-collapse-item-active {
            .ant-collapse-header  {
                color: #10b5c9;
            }
        }

        .ant-collapse-header {
            color: #fff;
            opacity: 0.8;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 24px;
            line-height: 1.3;
            margin-bottom: 10px;
            position: relative;
            padding: 20px 0;

            .anticon {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .ant-collapse-content {
            border-top: 0;
        }

        .ant-collapse-content-box {
            background-color: #01283c;
            color: #fff;
            padding: 0 40px 40px 0;
            font-family: "Proxima Nova Th";
            padding-right: 30px;
            
            p {
                font-family: "Proxima Nova Rg";
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}