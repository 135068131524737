.blogs_list {
  padding: 60px 0;

  .list_wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .blog_card {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin: 15px 0;
      a {
        margin: 15px;
        background-color: #fff;
        height: 100%;
        display: block;
        transition: 0.2s;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;

        &:hover {
          box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;

          .detail {
            h4 {
              color: #10b5c9;
            }
          }
        }
      }

      figure {
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
      }

      .detail {
        margin-top: 20px;
        padding: 10px;
        h4 {
          font-size: 20px;
          color: #003755;
          font-family: "Proxima Nova Lt";
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        p {
          font-size: 14px;
          line-height: 1.2;
          color: #248d9a;
          text-transform: uppercase;
          font-family: "Proxima Nova Rg";
          margin-top: 15px;

          svg {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

.blog_detail {
  padding: 80px 0;

  .wrap {
    width: 570px;
    max-width: 100%;
    margin: auto;
  }

  .blg_head {
    color: #003755;
    h4 {
      font-size: 35px;
      line-height: 1.2;
      font-family: "Proxima Nova Rg";
      color: #003755;
    }

    p {
      font-size: 14px;
      margin-top: 15px;
      display: block;

      svg {
        margin-left: 25px;
      }
    }
  }

  .blg_image {
    margin-top: 30px;

    img {
      width: 100%;
    }
  }

  .blg_text {
    margin-top: 50px;

    p {
      margin-bottom: 15px;
      line-height: 1.5;
    }
  }
}

@media screen and (max-width: 480px) {
  .blogs_list .list_wrap .blog_card {
    max-width: 100%;
    flex: 100%;
  }
}
