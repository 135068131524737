.banner {
  position: relative;
  width: 100%;
  height: 100vh;

  // &::after,
  // &::before {
  //   position: absolute;
  //   content: "";
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(0deg, #01283c 3%, rgba(255, 255, 255, 0) 100%);
  //   z-index: 1;
  // }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.31);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text {
    position: absolute;
    top: 20%;
    width: 590px;
    max-width: 100%;
    z-index: 2;
    color: #fff;

    h1 {
      font-size: 72px;
      color: #fff;
      font-family: "Proxima Nova Th";
      font-weight: 100;
    }

    h2 {
      font-family: "Proxima Nova Bl";
      color: #fff;
      font-size: 58px;
      line-height: 1.1;
      text-shadow: 1px 1px 0px #343131;
    }

    p {
      font-size: 16px;
      margin: 30px 0 40px;
    }

    a {
      background-color: #10b5c9;
      padding: 10px 20px;
      color: #fff;
      border-radius: 30px;
      font-size: 14px;
    }
  }
}

.abt {
  padding: 100px 0;
  width: 100%;
  overflow: hidden;
  background-color: #01283c;

  .wrap {
    display: flex;
    flex-wrap: wrap;

    .textcard {
      width: 50%;
    }

    .image {
      width: 50%;

      .map_footer {
        position: relative;
      }

      .tooltip {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: #10b5c9;
        text-transform: uppercase;
      }

      .nepal_map {
        width: 50vw;
        circle {
          fill: #10b5c9;
          cursor: pointer;
        }

        .prov {
          fill: #10435e;
        }

        .city {
          position: relative;
        }

        p {
          color: #10b5c9;
          text-align: center;
          font-family: "Proxima Nova Th";
          font-size: 16px;
        }
      }

      svg {
        width: 100%;

        path {
          fill: #2d74aa;
          // opacity: 0.5;

          &.mdp {
            stroke: none;
            animation-duration: 2s;
            animation-name: pulse;
            transform-origin: 50% 50%;
            animation-iteration-count: infinite;
            opacity: 1;
          }

          &.markerdata {
            stroke: none;
            position: relative;
            opacity: 1;
          }
        }
      }
    }

    .text {
      margin: 50px 0;
      margin-bottom: 100px;
      padding-right: 50px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        text-transform: uppercase;
        margin-bottom: 20px;
        color: #10b5c9;
      }

      p {
        color: #fff;
        width: 95%;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.impact_grow {
  background: #fff;
  padding: 100px 0 0;
  width: 100%;
  overflow: hidden;

  .head {
    text-align: center;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      width: 430px;
      max-width: 100%;
      margin: auto;
    }
  }

  .impact_accordion {
    display: flex;
    width: 100%;
    margin-top: 50px;

    .card {
      flex: 1 1;
      height: 542px;
      background-position: center !important;
      background-size: cover !important;
      position: relative;
      transition: 0.8s;
      cursor: pointer;
      // filter: grayscale(0.6);

      .counter {
        opacity: 0;
        visibility: none;
      }

      &:hover {
        &::after {
          opacity: 0.6;
        }

        .content {
          .expand_btn {
            transform: scale(1.1) translateX(-50%);

            img {
              transform: scale(0.8);
            }
          }
        }
      }

      &.expand {
        flex: 2.5 1;

        .counter {
          transition-delay: 0.9s;
          opacity: 1;
          visibility: visible;
          position: absolute;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          width: 90%;

          h2 {
            font-size: 48px;
            font-family: "Roboto Mono", monospace;
            color: #fff;
            font-weight: 800;
            position: relative;
            display: inline-flex;
            align-items: center;
            line-height: 1;

            span {
              font-size: 18px;
              font-weight: 200;
              margin-left: 5px;
            }
          }

          p {
            line-height: 1.3;
            width: 70%;
            max-width: 90%;
            margin: 15px auto 0;
          }
        }

        &::after {
          opacity: 0.2;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: #01283c;
        opacity: 0.4;
        top: 0;
        left: 0;
        z-index: 1;
        transition: 0.8s;
      }

      .content {
        position: relative;
        z-index: 2;
        color: #fff;
        width: 100%;
        height: 100%;

        .title {
          padding-top: 30px;

          h4 {
            color: #fff;
            font-size: 16px;
            text-transform: uppercase;
            text-align: center;
            font-family: "Proxima Nova Lt";
            line-height: 1.3;
            width: 137px;
            margin: auto;

            em {
              font-style: normal;
              display: block;
            }
          }
        }

        .expand_btn {
          opacity: 0.7;
          background: #fff;
          display: inline-flex;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);
          transition: 0.8s;

          img {
            width: 25px;
            transition: 0.5s;
          }
        }
      }

      .count {
        font-family: "Roboto Mono", monospace;
        text-align: center;
        color: #fff;
      }
    }
  }
}

.home_news_btn {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #01cbe1;
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.5s;
  display: inline-block;
  position: relative;
  opacity: 0.8;
}

.impacts {
  background-color: #01283c;
  min-height: 100vh;
  padding: 80px 0;
  padding-bottom: 80vh;
  position: relative;
  overflow: hidden;

  .map_bg {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;

    svg {
      width: 100%;

      path {
        stroke: #2d74aa;
        opacity: 0.2;

        &.markerdata {
          stroke: none;
          position: relative;
          opacity: 1;
          animation: pulse 5.5s infinite;
        }
      }
    }
  }

  .impact_head {
    color: #fff;

    h3 {
      text-transform: uppercase;
      color: #10b5c9;
    }

    p {
      width: 450px;
      max-width: 100%;
      margin-top: 20px;
    }
  }

  .impact-nums {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding-top: 200px;
    z-index: 2;
    justify-content: space-around;

    .icard {
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(7) {
        margin-top: -60px;
      }
      width: 33%;
      color: #fff;
      margin: 40px 0;
      text-align: center;

      .icon {
        img {
          width: 120px;
        }
      }

      h2 {
        font-size: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #56e3f4;
        font-family: "Proxima Nova Lt";

        small {
          font-size: 26px;
          font-family: "Proxima Nova Th";
          margin-left: 16px;
          line-height: 0;
        }
      }

      p {
        font-size: 16px;
        font-family: "Proxima Nova Rg";
        color: #10b5c9;
      }
    }
  }
}

.objectives {
  background-color: #01283c;
  padding: 100px 0;

  .objective_head {
    color: #fff;
    width: 570px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 70px;
    text-align: center;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      width: 31.5%;
      height: auto;
      transition: 0.8s;

      &:hover {
        transform: scale(1.1);
        cursor: pointer;

        .content {
          transform: scale(0.9);
        }
      }

      .content {
        padding: 50px 27px;
        display: flex;
        height: 88%;
        flex-flow: column;
        position: relative;
        transition: 0.8s;

        .head {
          display: flex;
          margin-bottom: 20px;
          justify-content: center;

          h2 {
            margin-left: 20px;
            line-height: 1.1;
          }
        }
      }

      img {
        width: 50px;
      }

      h1 {
        position: absolute;
        font-size: 96px;
        font-weight: 100;
        color: #fff;
        font-family: "Proxima Nova Bl";
        opacity: 0.1;
        right: 15px;
        bottom: 0;
      }

      p {
        color: #fff;
        text-align: center;
      }

      h2 {
        font-family: "Proxima Nova Rg";
        font-size: 22px;
        max-width: 190px;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }
}

.nepal {
  padding: 100px 0;
  background-color: #01283c;

  svg {
    width: 100%;

    path {
      stroke: #2d74aa;
      opacity: 0.2;

      &.mdp {
        stroke: none;
        animation-duration: 2s;
        animation-name: pulse;
        transform-origin: 50% 50%;
        animation-iteration-count: infinite;
        opacity: 1;
      }

      &.markerdata {
        stroke: none;
        position: relative;
        opacity: 1;
      }
    }
  }
}

.home_slider {
  background: #01283c;
  padding: 100px 0;

  .head {
    text-align: center;
    width: 570px;
    max-width: 100%;
    margin: auto;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
    }
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 80px;

    .num_wrap {
      width: 33%;

      &:nth-child(2) {
        .num_flex {
          justify-content: center;
        }

        p {
          text-align: center;
        }

        h6 {
          margin-left: 15px;
        }
      }

      &:last-child {
        p {
          text-align: center;
          margin-left: 55px;
        }

        .num_flex {
          justify-content: flex-end;
        }
      }

      p {
        color: #08697d;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 200;
        font-family: "Proxima Nova Lt";
      }

      .num_flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        h4 {
          font-size: 78px;
          color: #56e3f4;
          margin-right: 10px;
          line-height: 1;
          font-family: "Proxima Nova Lt";
          letter-spacing: -1px;
        }

        h6 {
          color: #56e3f4;
          font-size: 28px;
          font-weight: 200;
          line-height: 1;
        }

        .icon {
          width: 25%;
          img {
            width: 100px;
          }
        }
      }
    }
  }
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  display: none;
}

.slider_range {
  margin-top: 140px;
  padding-bottom: 80px;

  span.ant-tooltip-arrow-content {
    display: none;
  }

  .ant-slider {
    max-width: 80%;
    margin: auto;
  }

  .ant-slider-step {
    height: 10px;
    background-color: #003755;
  }

  .ant-slider-handle {
    width: 100px;
    height: 100px;
    margin-top: -46px;
    background-color: #56e2f3;
    border: solid 0px #91d5ff;
    position: relative;
    z-index: 2;

    p {
      color: #56e2f3;
      margin-top: 20px;
      font-size: 16px;
      position: absolute;
      width: 190px;
      text-align: center;
      bottom: -60px;
      left: -50px;
      line-height: 1.2;
      font-family: "Proxima Nova Th";
    }
  }
}

.ant-tooltip-inner {
  background-color: transparent;
  padding: 0;
  min-height: auto;
  font-size: 24px;
  transform: translateY(82px);
  font-family: "Proxima Nova Lt";
  box-shadow: none;
  cursor: pointer;
  z-index: 1;
}

.home_news {
  padding: 100px 0;
  overflow: hidden;
  background-color: #f9f9f9;

  .news_head {
    width: 25%;
    margin: auto;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .news_new {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;

    .main_featured {
      width: 48%;

      .hn_card {
        picture {
          img {
            height: 342px;
          }
        }
      }
    }

    .news_list {
      width: 48%;

      .hn_card {
        margin: 30px 0;

        &:first-child {
          margin-top: 0;
        }

        a {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        picture {
          width: 30%;

          img {
            height: 140px;
          }
        }

        article {
          width: 70%;
        }
      }
    }

    .hn_card {
      background-color: #fff;

      a {
        color: #003755;
      }

      picture {
        img {
          width: 100%;
          object-fit: cover;
        }
      }

      article {
        padding: 30px;

        p {
          display: flex;

          .anticon {
            margin-right: 8px;

            svg {
              width: 14px;
            }
          }
        }

        time {
          font-size: 14px;
          margin-bottom: 10px;
          display: block;
        }

        h4 {
          color: #003755;
          font-size: 20px;
          line-height: 1.2;
          font-family: "Proxima Nova Lt";
          text-transform: uppercase;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 48px;
        }
      }
    }
  }

  .wrap {
    width: 1240px;
    display: flex;
    flex-wrap: wrap;

    .news_slider {
      width: 75%;

      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0;
      }
    }
  }

  .news_slider {
    a {
      color: #003755;

      &:hover {
        h4 {
          color: #10b5c9;
        }
      }
    }

    .item {
      background-color: #fff;
      height: auto;
    }

    picture {
      img {
        width: 100%;
        height: 225px;
        object-fit: cover;
      }
    }

    article {
      padding: 30px 20px;

      p,
      time {
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
      }

      h4 {
        color: #003755;
        font-size: 20px;
        line-height: 1.2;
        font-family: "Proxima Nova Lt";
      }
    }
  }
}

.media_home {
  padding: 100px 0;
  background: #b5dcf14f;

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    width: 35%;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
    }
  }

  .media_list {
    width: 60%;

    .media_card {
      margin: 30px 0;

      a {
        margin: 15px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
        transition: 0.2s;
        background: #fff;

        figure {
          width: 30%;

          img {
            width: 100%;
            height: 100px;
            object-fit: contain;
            padding: 20px;
          }
        }

        .desc {
          width: 70%;
          padding: 15px;

          label {
            color: #10b5c9;
          }

          h4 {
            font-size: 20px;
            color: #003755;
            font-family: "Proxima Nova Lt";
            margin-bottom: 5px;
            text-transform: uppercase;
          }

          p {
            font-size: 14px;
            line-height: 1.2;
            color: #248d9a;
            text-transform: uppercase;
            font-family: "Proxima Nova Rg";
          }
        }
      }
    }
  }
}

.video {
  padding: 80px 0;

  .home_video_slider {
    .item {
      .img {
        position: relative;

        img {
          width: 80%;
          height: 612px;
          object-fit: cover;
        }

        button {
          position: absolute;
          top: 50%;
          left: 40%;
          transform: translate(-50%, -50%);
          background: #10b5c9;
          border: 0;
          border-radius: 50%;
          display: inline-flex;
          width: 70px;
          height: 70px;
          align-items: center;
          justify-content: center;
          z-index: 2;
          cursor: pointer;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid #ffffff;
            -webkit-animation: ripple 2s linear infinite;
            animation: ripple 2s linear infinite;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid #ffffff;
            -webkit-animation: ripple 2s linear 1s infinite;
            animation: ripple 2s linear 1s infinite;
          }

          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }

      .text {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 40px;
        width: 40%;
        background: #10b5c9;
        color: #fff;

        h3 {
          text-transform: uppercase;
          font-size: 32px;
          margin-bottom: 20px;
          color: #fff;
        }
      }
    }
  }
}

.video_modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .closeBtn {
    position: absolute;
    top: 50px;
    right: 50px;
    background: #10b5c9;
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  iframe {
    width: 80%;
    height: 430px;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.insta_feed {
  padding: 100px 0;
  background: #01283c;

  .head {
    max-width: 100%;
    margin: auto;
    width: 400px;
    text-align: center;

    h3 {
      color: #10b5c9;
      text-transform: uppercase;
      margin-bottom: 40px;
    }
  }

  ._2R-kh {
    justify-content: center;
  }

  ._wPDyp {
    height: 240px;
    margin: 15px;
    width: 240px;
    flex-basis: calc(100% / 5 - 15px);
  }
}

@keyframes pulse {
  0% {
    stroke-width: 2;
    stroke: #e4a261;
  }

  50% {
    stroke-width: 50;
    stroke: #e4a261;
    opacity: 0.3;
  }

  100% {
    stroke-width: 1;
    stroke: #e87707;
  }
}

@media screen and (max-width: 800px) {
  
  .banner .text {
    top: 50%;
  }

  .insta_feed ._wPDyp {
    height: 130px;
  }

  .video {
    .home_video_slider {
      .item {
        .img {
          img {
            width: 100%;
          }

          button {
            left: 50%;
          }
        }

        .text {
          position: relative;
          width: 90%;
          margin: auto;
          top: -80px;
          transform: translateY(0);
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .banner .text {
    width: 100%;

    h1 {
      font-size: 55px;
    }
  }

  .abt .wrap .textcard {
    width: 100%;
  }

  .abt .wrap .text {
    padding-right: 0;
    margin-bottom: 100px;
  }

  .abt .wrap .text p {
    width: 100%;
  }

  .abt .wrap .image {
    width: 100%;
    margin-top: 50px;

    svg {
      padding-left: 0;
      height: 280px;
    }
  }

  .abt .wrap .image .nepal_map {
    width: 100%;
  }

  .impacts .impact-nums .icard {
    width: 100%;
  }

  .impacts .impact-nums .icard:nth-child(2),
  .impacts .impact-nums .icard:nth-child(5),
  .impacts .impact-nums .icard:nth-child(7) {
    margin-top: 40px;
  }

  .objectives .wrap .card {
    width: 100%;
    margin: 2px 0;
  }

  footer .footer_top .wrap {
    display: block;

    h4 {
      text-align: center;
    }
  }

  .social_icon {
    margin-top: 20px;
    text-align: center;
  }

  footer .footer_bottom .logo {
    justify-content: center;
  }

  footer .footer_bottom .wrap {
    display: block;
    text-align: center;

    .copyright {
      margin-top: 20px;
    }
  }

  .home_slider .wrap .num_wrap {
    width: 100%;
    margin: 20px 0;
    padding-left: 20px;

    p {
      text-align: left !important;
      margin-left: 0 !important ;
    }

    .num_flex {
      justify-content: start !important;
    }
  }

  .impact_grow .impact_accordion {
    flex-direction: column;

    .card.expand {
      /* flex: 2.5 1; */
      flex: auto;
      height: 400px;
    }
  }

  .home_news .wrap {
    width: 100%;

    .news_head {
      width: 100%;
    }

    .news_slider {
      width: 100%;
    }
  }

  .home_news .wrap .news_head .slider_nav {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .home_news .news_head {
    width: 100%;
    text-align: center;
  }
  .home_news .news_new .main_featured {
    margin-bottom: 45px;
  }

  .home_news .news_new .main_featured,
  .home_news .news_new .news_list {
    width: 100%;
  }

  .media_home {
    .heading {
      width: 100%;
      text-align: center;
    }

    .media_list {
      width: 100%;
    }
  }
}
