.preloader_fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  background: #10b5c9;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin-dot i {
    background-color: #fff;
  }

  img {
    width: 120px;
  }
}
