$turquoise : #10b5c9;
$highlight: #ffda26;
// $highlight: #EDD83D;


body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Proxima Nova Rg';
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;

    // strong {
    //     font-family: 'Proxima Nova Lt';
    // }
}

h3 {
    font-size: 42px;
    font-family: 'Proxima Nova Rg';
    line-height: 1.1;

}

figure {
    margin: 0
}

a {
    text-decoration: none;
}

p {
    font-size: 18px;
    font-family: 'Proxima Nova Rg';
    line-height: 1.55;
    margin: 0;
}

article {
    p {
        margin-bottom: 15px;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 15px;
    }

    li {
        font-size: 18px;
        font-family: 'Proxima Nova Rg';
        line-height: 1.55;
    }

    h6 {
        font-size: 18px;
        margin-top: 30px;
        font-family: 'Proxima Nova Lt';
    
    }
}

.container {
    max-width: 1100px;
    margin: auto;
}

.container_big {
    max-width: 1250px;
    margin: auto;
}

@media screen and (max-width: 1050px) {
.container {
    padding: 0 15px;
}

}

@media screen and (max-width: 480px) {
    h3 {
        font-size: 34px;
    }

    .container, .container_big {
        max-width: 95%;
        margin: auto;
    }

    .banner .text h1 {
        font-size: 55px;
    }

    .abt .wrap .textcard {
        width: 100%;
    }

    .abt .wrap .text {
        padding-right: 0;
        margin-bottom: 100px;
    }

    .abt .wrap .text p {
        width: 100%;
    }

    .abt .wrap .image {
        width: 100%;
    }

    .abt .wrap .image svg {
        padding-left: 0;
    }

    .impacts .impact-nums .icard {
        width: 100%;
    }

    .impacts .impact-nums .icard:nth-child(2), .impacts .impact-nums .icard:nth-child(5), .impacts .impact-nums .icard:nth-child(7) {
        margin-top: 40px;
    }

    .objectives .wrap .card {
        width: 100%;
        margin: 2px 0;
    }

    footer .footer_top .wrap {
        display: block;

        h4 {
            text-align: center;
        }
    }

    .social_icon {
        margin-top: 20px;
        text-align: center;
    }

    footer .footer_bottom .logo {
        justify-content: center;
    }

    footer .footer_bottom .wrap {
        display: block;
        text-align: center;

        .copyright {
            margin-top: 20px;
        }
    }
}