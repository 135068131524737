.news {
  // background-image: linear-gradient(to right top, #ffffff, #f8fcff, #ebfaff, #dafaff, #cafafe);
  padding: 60px 0;

  .wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .news_card {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin: 15px 0;

      figure {
        transition: 0.8s;

        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
      }

      .detail {
        margin-top: 20px;
        padding: 10px;

        h4 {
          font-size: 20px;
          color: #003755;
          font-family: "Proxima Nova Lt";
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        h5,
        time {
          font-size: 14px;
          line-height: 1.2;
          color: #248d9a;
          text-transform: uppercase;
          font-family: "Proxima Nova Rg";
        }
      }
    }

    .news_wrap {
      margin: 15px;
      background-color: #fff;
      height: 100%;
      display: block;
      transition: 0.2s;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

      &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        h4 {
          color: #10b5c9;
        }
      }
    }
  }
}

.news_detail {
  padding: 80px 0;
  // background-image: linear-gradient(to right bottom, #ffffff, #f8fcff, #ebfaff, #dafaff, #cafafe);

  .wrap {
    width: 570px;
    max-width: 100%;
    margin: auto;
  }

  .nd_head {
    color: #003755;

    h4 {
      font-size: 35px;
      line-height: 1.2;
      font-family: "Proxima Nova Rg";
      color: #003755;
    }

    p,
    time {
      font-size: 14px;
      margin-top: 15px;
      display: block;
    }
  }

  .nd_figure {
    margin-top: 30px;

    img {
      width: 100%;
    }
  }

  .nd_text {
    margin-top: 50px;

    p {
      margin-bottom: 15px;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .news .wrap .news_card {
    width: 100%;
    max-width: 100%;
    flex: auto;
  }
}
